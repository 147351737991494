<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="saved-address-main box-flex">
      <div class="container review-main text-capitalize position-relative box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="center-card | d-flex flex-column">
              <div class="center-card-box top-sticky border-bottom">
                <div class="resturant-details | p-3">
                  <h4 class="text-start">{{ $t('Saved Address') }}</h4>
                </div>
              </div>
              <div class="center-card-box">
                <div class="orders-inner-main | p-3" v-if="address.length > 0">
                  <div class="orders-lst">
                    <div class="inner-data">
                      <div class="saved-address-main">
                        <div class="d-flex justify-content-between" v-for="(addresses, index) in address" :key="index">
                          <div class="saved-address w-100" @click="onRowClicked(addresses.shiping_id)">
                            <p class="text-capitalize mb-1">{{ $t(addresses.adress_clarification) }}</p>
                            <span v-if="addresses.flat_no == null || addresses.flat_no == ''">{{ addresses.house_name +
                              '' + addresses.Shiping_address }}</span>
                            <span v-else>{{ addresses.flat_no + ', ' + addresses.house_name + ' ' +
                              addresses.Shiping_address }}</span>
                          </div>
                          <button type="submit" class="btn float-right delivery-link"
                            @click="DeleteAddressById(addresses.shiping_id)"><i class="fas fa-trash"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="order-offers">
                  <div class="text-center my-2" v-if="loading">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <div v-else class="noadd-cls">
                    <p>{{ $t('No Address') }}</p>
                    <p>{{ $t('Tap to Add New Address button to add new address') }}</p>
                  </div>
                </div>
              </div>
              <div class="center-card-box  bottom-sticky mt-auto">
                <div class="full-btn">
                  <router-link :to="{ name: 'add-address' }">{{ $t('Add New Address') }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
    <Footer></Footer>
  </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";
import NavBar from "../components/NavBar.vue";
import Footer from '../components/Footer.vue';
import AlertModal from '../components/AlertModal.vue';

export default {
  name: 'AddressList',
  components: { NavBar, Footer, AlertModal },
  data() {
    return {
      address: [],
      loading: true,
      isAlert: false,
      AlertData: {},
      shiping_id: 0,
    };
  },

  mounted() {
    this.AddressesList();
  },
  methods: {
    ...mapActions("address", ["getAddress", "removeAddress"]),
    ...mapMutations("address", ["primaryAddressMutation"]),


    AddressesList() {
      this.loading = true;
      this.getAddress({
        devicetoken: "",
        devicetype: "",
        uniqueid: "",
        type: "",
        is_langauge: this.$store.state.lang.locale
      }).then((data) => {
        if (data.code == 200) {
          this.loading = false;
          this.address = data.Result;
        } else {
          this.loading = false;
          this.address = data.Result;
          if (this.address.length == 0) {
            this.$store.commit("address/primaryAddressMutation", {});
          }
        }
      });
    },

    DeleteAddressById(shiping_id) {
      this.shiping_id = shiping_id;
      this.AlertData = { displayMessage: this.$t('Are you sure delete?'), isSetClass: 'addrs-modal-cls' };
      this.isAlert = true;
      window.$(this.$refs.AlertModal).modal();
    },

    DeleteAddress() {
      this.removeAddress({
        shiping_id: this.shiping_id,
        vendor_id: "",
        is_langauge: this.$store.state.lang.locale,
      }).then((data) => {
        if (data.code == 200) {
          this.AddressesList();
        }
        if (data.code == 101) {
          this.$toasted.error(data.msg, { duration: 2000 });
        }
      });
    },

    onRowClicked(shiping_id) {
      this.$router.push({ name: 'update-address', params: { shiping_id: shiping_id } })
    },

    //Modal Closed
    onModalCloseFun(value) {
      this.isAlert = false;
      if (value == 'yes') {
        this.DeleteAddress();
      }
    },
  },
};
</script>